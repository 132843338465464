import React, { useEffect, useMemo, useState } from 'react'
import { PageTitle, useLayout } from '../../../_metronic/layout/core'
import { axiosClientAuth } from '../../utils/axiosClient';
import moment from 'moment';
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { KTIcon } from '../../../_metronic/helpers';

export default function BulkReport() {
  const [data, setData] = useState([]);
  const { setCurrentBulkData } = useLayout('')

  useEffect(() => {
    axiosClientAuth.get("/main/bulk-ip-upload").then(resp => {
      setData(resp.data?.data);
    }).catch(err => {
      console.log(err);
    })
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: 'Sr No',
        accessor: 'srno',
        Cell: ({ ...props }) => {
          return <>{props.row.index + 1}</>
        },
      },
      {
        Header: 'Date',
        accessor: 'created_at',
        Cell: ({ ...props }) => {
          return (
            <>
              <span>{moment(props.value).format('DD MMM YYYY')}</span>
              <span className='d-block text-muted fw-semibold'>
                {moment(props.value).format('LT')}
              </span>
            </>
          )
        },
      },
      {
        Header: 'File Name',
        accessor: 'file_name',
      },
      {
        Header: 'Duplicate',
        accessor: 'duplicate_count',
      },
      {
        Header: 'Req. Status',
        accessor: 'status',
        Cell: ({ ...props }) => (
          props.value === 'completed' ? (
            <span className='badge badge-light-success fw-bold px-4 py-3'>
              Completed
            </span>
          ) : (
            <span className='badge badge-light-warning fw-bold px-4 py-3'>
              Processing
            </span>
          )
        )
      },
      {
        Header: 'Completed',
        accessor: 'completed_percentage',
        Cell: ({ ...props }) => (
          <span>
            {props.value}%
          </span>
        )
      },
      {
        Header: 'ACTION',
        Cell: ({ ...props }) => {
          return (
            <>
              <button
                className='btn h-35px w-75px btn-icon btn btn-light-primary me-3'
                onClick={() => setCurrentBulkData(props.row.original)}
                id='kt_drawer_bulk_ip_toggle'
              // disabled={Boolean(props?.row?.original?.status)}
              >
                View
              </button>
            </>
          )
        },
      },
    ],
    []
  )

  const mTable = useTable({
    columns,
    data,
  },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  return (
    <>
      <PageTitle breadcrumbs={[]}>Bulk IP Upload</PageTitle>
      <div className='row g-5 g-xl-8'>
        <div className='col-12'>
          <div className={`card`}>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Bulk IP File List</span>
                <span className='text-muted fw-semibold fs-7'>
                  Total {mTable.rows?.length} File
                </span>
              </h3>
              <div className='d-flex align-items-center'>
                {/* <RangePicker
                  className='bg-light text-white border-0 shadow-none w-100'
                  style={{ height: '34px' }}
                  onChange={onDateFilterChange}
                /> */}
                {/* <select
                  className='form-select w-auto form-select-sm form-select-solid mx-2'
                  value={mTable.headers[3].filterValue}
                  onChange={(e) => mTable.headers[3].setFilter(e.target.value)}
                >
                  {['All', 'true', 'false'].map((item) => (
                    <option value={item == 'All' ? '' : item}>
                      {item == "true" ? 'Enabled' : item == "false" ? 'Disabled' : 'All'}
                    </option>
                  ))}
                </select>
                <select
                  className='form-select w-auto form-select-sm form-select-solid me-2'
                  value={mTable.headers[4].filterValue}
                  onChange={(e) => mTable.headers[4].setFilter(e.target.value)}
                >
                  {['All', 'true', 'false'].map((item) => (
                    <option value={item == 'All' ? '' : item}>
                      {item == "true" ? 'Blacklisted' : item == "false" ? 'Whitelisted' : 'All'}
                    </option>
                  ))}
                </select> */}
                <div className='d-flex align-items-center position-relative my-1 ms-2'>
                  <KTIcon iconName='magnifier' className='text-white-500 position-absolute ms-2' />
                  <input
                    type='text'
                    id='kt_filter_search'
                    className='form-control form-control-solid form-select-sm w-150px ps-9'
                    placeholder='Search IP'
                    value={mTable.state.globalFilter}
                    onChange={(e) => mTable.setGlobalFilter(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className='card-body pt-3'>
              <div className='table-responsive'>
                <table
                  {...mTable.getTableProps()}
                  className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                >
                  <thead>
                    {mTable.headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            className={column.render('Header') == 'ACTION' ? 'w-200px' : ''}
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                          >
                            <span className='text-gray-400 fs-7'>{column.render('Header')}</span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <KTIcon iconName='down' className='text-gray ms-1' />
                              ) : (
                                <KTIcon iconName='up' className='text-gray ms-1' />
                              )
                            ) : (
                              ''
                            )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...mTable.getTableBodyProps()}>
                    {(mTable.page.length > 0 &&
                      mTable.page.map((row) => {
                        mTable.prepareRow(row)
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => (
                              <td className='fw-bold' {...cell.getCellProps()}>
                                {cell.render('Cell')}
                              </td>
                            ))}
                          </tr>
                        )
                      })) || (
                        <tr>
                          <td colSpan={5} className='text-center'>
                            <span className='fw-bold'>No data available in table</span>
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
                <div className='d-flex justify-content-between align-items-center mt-5'>
                  <div>
                    <select
                      className='form-select form-select-sm form-select-solid'
                      value={mTable.state.pageSize}
                      onChange={(e) => mTable.setPageSize(parseInt(e.target.value))}
                    >
                      {[10, 25, 50, 100].map((item) => (
                        <option>{item}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <span>
                      Page{' '}
                      <strong>
                        {mTable.state.pageIndex + 1} of {mTable.pageOptions.length}
                      </strong>
                    </span>
                  </div>
                  <div>
                    <button
                      className='btn btn-sm'
                      onClick={() => mTable.previousPage()}
                      disabled={!mTable.canPreviousPage}
                    >
                      <KTIcon iconName='left' className='text-gray fs-1 me-5' />
                    </button>
                    <button
                      className='btn btn-sm'
                      onClick={() => mTable.nextPage()}
                      disabled={!mTable.canNextPage}
                    >
                      <KTIcon iconName='right' className='text-gray fs-1 me-5' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}