import { useFormik } from 'formik'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import * as Yup from 'yup'
import { axiosClientAuth } from '../../../app/utils/axiosClient'
import { KTIcon } from '../../helpers'
import Swal from 'sweetalert2'
import clsx from 'clsx'

type Props = {
  show: boolean
  handleClose: () => void
}

const validationSchema = Yup.object().shape({
  name: Yup.string().min(3, 'Minimum 3 symbols').required('Name is required'),
  address: Yup.string().min(3, 'Minimum 3 symbols').required('Name is required'),
})

const initialValues = {
  name: '',
  address: '',
}

const IPRegistrationModal = ({ show, handleClose }: Props) => {
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        const res = await axiosClientAuth.post('/main/ip-address', {
          name: values.name,
          address: values.address,
        })
        if (res.data.status) {
          Swal.fire({
            titleText: 'Success!',
            text: 'IP Created Successfully.',
            icon: 'success',
            confirmButtonText: 'Ok! Got It',
          }).then((isConfirm) => {
            if (isConfirm) {
              window.location.reload()
            }
          })
        }
        setLoading(false)
      } catch (error: any) {
        if (error?.response?.data?.data?.address){
          Swal.fire({
            titleText: 'Error!',
            text: 'IP Address is already exists.',
            icon: 'error',
            confirmButtonText: 'Ok! Got It',
          })
        }
          setLoading(false)
      }
    },
  })

  const closeModal = () => {
    formik.resetForm()
    handleClose()
  }

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-body py-lg-10 px-lg-10'>
        <div className='d-flex justify-content-end'>
          <button
            className='ms-auto btn btn-sm btn-icon btn-active-color-primary'
            onClick={closeModal}
          >
            <KTIcon iconName='cross' className='text-gray fs-1' />
          </button>
        </div>

        <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
          <div className='mb-13 text-center'>
            <h1 className='mb-3'>IP Registration</h1>
            <div className='text-muted fw-semibold fs-5'>
              If you need more info, please check{' '}
              <a href='#' className='fw-bold link-primary'>
                Our Guidelines
              </a>
              .
            </div>
          </div>

          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : null}

          <div className='fv-row mb-8'>
            <label className='form-label fs-6 fw-bolder text-dark required'>IP Name</label>
            <input
              placeholder='Enter IP Name'
              {...formik.getFieldProps('name')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.name && formik.errors.name },
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              type='text'
              name='name'
              autoComplete='off'
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.name}</span>
              </div>
            )}
          </div>

          <div className='fv-row mb-8'>
            <label className='form-label fs-6 fw-bolder text-dark required'>IP Address</label>
            <input
              placeholder='Enter IP Address'
              {...formik.getFieldProps('address')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.address && formik.errors.address },
                {
                  'is-valid': formik.touched.address && !formik.errors.address,
                }
              )}
              type='text'
              name='address'
              autoComplete='off'
            />
            {formik.touched.address && formik.errors.address && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.address}</span>
              </div>
            )}
          </div>

          {/* begin::Action */}
          <div className='text-center mb-10'>
            <button type='reset' className='btn btn-light me-3' onClick={closeModal}>
              <span className='indicator-label'>Cancel</span>
            </button>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default IPRegistrationModal
