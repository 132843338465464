/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { KTIcon } from '../../../helpers'
import { useLayout } from '../../../layout/core'
import { getClassNameByStatus } from '../../../../app/utils/helper';

const DrawerBulkIP: FC = () => {
  const { currentBulkData } = useLayout();

  return (
    <div
      id='kt_drawer_bulk_ip'
      className='bg-white'
      data-kt-drawer='true'
      data-kt-drawer-name='chat'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'300px', 'md': '500px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_drawer_bulk_ip_toggle'
      data-kt-drawer-close='#kt_drawer_bulk_ip_close'
    >
      <div className='card w-100 rounded-0' id='kt_drawer_bulk_ip_messenger'>
        <div className='card-header pe-5' id='kt_drawer_bulk_ip_messenger_header'>
          <div className='card-title'>
            <div className='d-flex justify-content-center flex-column me-3'>
              <a href='#' className='fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1'>
                {currentBulkData?.file_name}
              </a>

              <div className='mb-0 lh-1'>
                <span className={`badge badge-${currentBulkData?.completed_percentage == 0 ? 'danger'
                : currentBulkData?.completed_percentage > 10 && currentBulkData?.completed_percentage < 100 ? 'warning' 
                : 'success'} badge-circle w-10px h-10px me-1`}></span>
                {/* <span className='fs-7 fw-semibold text-gray-400'>Active</span> */}
              </div>
            </div>
          </div>

          <div className='card-toolbar'>
            {/* <div className='me-2'>
              <button
                className='btn btn-sm btn-icon btn-active-light-primary'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <i className='bi bi-three-dots fs-3'></i>
              </button>
            </div> */}

            <div className='btn btn-sm btn-icon btn-active-light-primary' id='kt_drawer_bulk_ip_close'>
              <KTIcon iconName='cross' className='fs-2' />
            </div>
          </div>
        </div>

        <div className='card-body'>
          <div className='timeline'>
            <div className='timeline'>
              {currentBulkData?.report?.map((item: any, index: number) => (
                <div className='row'>
                  <div className='col'>
                    <div className='timeline-item'>
                      <div className='timeline-line w-40px'></div>

                      <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                        <div
                          className={`symbol-label bg-light-${item.status == 'failed' ? 'danger' : 'success'}`}
                        >
                          <KTIcon
                            iconName='fasten'
                            className={`fs-2 text-${item.status == 'failed' ? 'danger' : 'success'}`}
                          />
                        </div>
                      </div>

                      <div className='timeline-content mb-10 mt-2'>
                        <div className='pe-3 mb-5'>
                          <div className='fw-bold text-gray-600 fs-6'>{item?.name}</div>
                          <div className='fs-5 fw-bold'>{item?.address}</div>
                          <div className='text-muted fw-semibold'>{item?.created_at}</div>
                          <div className='text-muted fw-semibold'>{item?.reason}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className='col-4'> */}
                  {/* <button
                      className='btn h-35px btn-icon btn btn-light-primary me-3'
                      // onClick={() => onStatusChange(ip, false)}
                    >
                      <KTIcon iconName='check' className='text-primary text-hover-white fs-1' />
                    </button>
                    <button
                      className='btn h-35px btn-icon btn btn-light-danger me-3'
                      // onClick={() => onStatusChange(ip, true)}
                    >
                      <KTIcon iconName='cross' className='text-danger text-hover-white fs-1' />
                    </button> */}
                  {/* </div> */}
                </div>
              ))}
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export { DrawerBulkIP }