import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import {
  CardsWidget17,
  TilesWidget1,
  TilesWidget2,
  TilesWidget3,
  TilesWidget4,
  TilesWidget5,
} from '../../../_metronic/partials/widgets'
import TableWidgetIPList from '../../../_metronic/partials/widgets/_new/tables/TableWidgetIPList'
import { MixedWidgetIpStats } from '../../../_metronic/partials/widgets/_new/mixed/MixedWidgetIpStats'
import { useEffect, useState } from 'react'
import { axiosClientAuth } from '../../utils/axiosClient'

const DashboardPage = () => {
  const [data, setData] = useState({
    ipaddress: [
    ],
    ipaddress_count: {
      total: 0,
      pending: 0,
      approved: 0,
      rejected: 0,
    },
  })
  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    axiosClientAuth.get("/main/vendor-dashboard").then((res) => {
      setDashboardData(res.data.data);
    })
    .catch((err) => {
      console.log(err)
    })
    axiosClientAuth
      .get('/main/ip-address')
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  return (
    <>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          {
            data &&
            <MixedWidgetIpStats
              className='card-xxl-stretch mb-xl-3'
              chartColor='success'
              chartHeight='150px'
              data={data}
              data1={dashboardData}
            />
          }
        </div>

        <div className='col-xl-8'>
          <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
            <div className='col-xl-3'>
              <TilesWidget1 className='card-xl-stretch' />
            </div>
            <div className='col-xl-9'>
              <TilesWidget4 className='card-xl-stretch' />
            </div>
          </div>

          <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
            <div className='col-xl-6'>
              <CardsWidget17
                className='card-xl-stretch mb-5 mb-xl-8'
                id="1"
                title="Whitelist Logs"
                data={data?.ipaddress_count}
              />
              {/* <CardsWidget17
              className='card-xl-stretch'
              id="2"
              title="Operator Status"
            /> */}
              <div className='row gx-5 gx-xl-8'>
                <div className='col-xl-6'>
                  <TilesWidget5
                    className='card-xxl-stretch bg-primary'
                    svgIcon='element-11'
                    titleClass='text-white'
                    descriptionClass='text-white'
                    iconClass='text-white'
                    title='0'
                    description='Total SMS Sent'
                  />
                </div>
                <div className='col-xl-6'>
                  <TilesWidget5
                    className='card-xxl-stretch bg-body'
                    svgIcon='rocket'
                    titleClass='text-dark'
                    descriptionClass='text-muted'
                    iconClass='text-success'
                    title='0'
                    description='Total SMS Failed'
                  />
                </div>
              </div>
            </div>
            <div className='col-xl-6'>
              <TilesWidget2 className='card-xl-stretch h-100 mb-5 mb-xl-8' />
              {/* <TilesWidget3 className='card-xl-stretch mb-5 mb-xl-8' /> */}
            </div>
          </div>
        </div>
      </div>

      <div className='row g-5 g-xl-8'>
        <div className='col-12'>
          {
            data &&
            <TableWidgetIPList className='card-xxl-stretch mb-5 mb-xl-10' />
          }
        </div>
      </div>
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
