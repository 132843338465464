export const categorizeItemsByStatus = (status: boolean | null) => {
  if (status === null) {
    return 'Pending';
  } else if (status === true) {
    return 'Enabled';
  } else {
    return 'Disabled';
  }
}

export const getClassNameByStatus = (status: boolean | null) => {
  if (status === null) {
    return 'warning';
  } else if (status === true) {
    return 'success';
  } else {
    return 'danger';
  }
}

export const categorizeItemsForBlacklist = (status: boolean | null) => {
  if (status === null) {
    return 'Pending';
  } else if (status === false) {
    return 'Whitelisted';
  } else {
    return 'Blacklisted';
  }
}