/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Outlet, Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = `none`
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      document.body.style.backgroundImage = `url(${toAbsoluteUrl('/media/patterns/header-bg.jpg')})`
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      <div className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center'>
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>

          <img
            className='mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20'
            src={toAbsoluteUrl('/media/auth/agency-dark.png')}
            alt=''
          />

          <h1 className='fs-2qx fw-bolder text-center mb-7'>
            Transparency Data Reservoir
          </h1>

          <div className='text-gray-600 fs-base text-center fw-semibold'>
            Transparency Data reservoir makes available and provides{' '}
            <a href='javascript:void(0)' className='opacity-75-hover text-primary me-1'>
              Transparency
            </a>{' '}
            on the DLR reports and{' '}
            <a href='javascript:void(0)' className='opacity-75-hover text-primary me-1'>
              scrubbing reports
            </a>{' '}
            of the SMS campaigns sent by an Enterprise.
          </div>
        </div>
      </div>

      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10'>
        <div className='bg-body rounded-4 d-flex flex-center flex-column flex-lg-row-fluid'>
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export { AuthLayout }
