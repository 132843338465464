import React, { useEffect, useMemo, useState } from 'react'
import { Column, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import moment from 'moment'
import clsx from 'clsx'
import { KTIcon } from '../../../../helpers'
import { DatePicker } from 'antd'
import { axiosClientAuth } from '../../../../../app/utils/axiosClient'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import {
  categorizeItemsByStatus,
  categorizeItemsForBlacklist,
  getClassNameByStatus,
} from '../../../../../app/utils/helper'

type ManageIPType = {
  srno?: string
  address?: string
  created_at?: Date
  status: boolean | null
  name: string
  uuid: string
  is_blacklisted_by_tcil: boolean | null
}

const TableWidgetIPList = ({ className }) => {
  const [data, setData] = useState({
    ipaddress: [
    ],
    ipaddress_count: {
      total: 0,
      pending: 0,
      approved: 0,
      rejected: 0,
    },
  })
  const [filteredData, setFilteredData] = useState({
    ipaddress: [
    ],
    ipaddress_count: {
      total: 0,
      pending: 0,
      approved: 0,
      rejected: 0,
    },
  })

  const [dateFilter, setDateFilter] = useState("")

  useEffect(() => {
    axiosClientAuth
      .get('/main/ip-address')
      .then((res) => {
        setData(res.data.data);
        setFilteredData(res.data.data);
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const columns: ReadonlyArray<Column<ManageIPType>> = useMemo(
    () => [
      {
        Header: 'Sr No',
        accessor: 'srno',
        Cell: ({ ...props }) => {
          return <>{props.row.index + 1}</>
        },
      },
      {
        Header: 'IP Name',
        accessor: 'name',
        Cell: ({ ...props }) => {
          return (
            <span>
              {props.value}
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip id={`tooltip-manage-ip-${props.row.original.address}`}>
                    {props.row.original.address}
                  </Tooltip>
                }
              >
                <i className='fas fa-exclamation-circle ms-2 fs-7' />
              </OverlayTrigger>
            </span>
          )
        },
      },
      {
        Header: 'Date',
        accessor: 'created_at',
        Cell: ({ ...props }) => {
          return (
            <>
              <span>{moment(props.value).format('DD MMM YYYY')}</span>
              <span className='d-block text-muted fw-semibold'>
                {moment(props.value).format('LT')}
              </span>
            </>
          )
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ ...props }) => {
          return (
            <span
              className={`badge badge-light-${getClassNameByStatus(props.value)} fw-bold px-4 py-3`}
            >
              {categorizeItemsByStatus(props.value)}
            </span>
          )
        },
      },
      {
        Header: 'TCIL Status',
        accessor: 'is_blacklisted_by_tcil',
        Cell: ({ ...props }) => {
          return (
            <span
              className={`badge badge-light-${getClassNameByStatus(!props.value)} fw-bold px-4 py-3`}
            >
              {categorizeItemsForBlacklist(props.value)}
            </span>
          )
        },
      },
      {
        Header: 'ACTION',
        Cell: ({ ...props }) => {
          return (
            <>
              <button
                className='btn h-35px btn-icon btn btn-light-primary me-3'
                onClick={() => onStatusChange(props.row.original.uuid, true)}
                disabled={Boolean(props?.row?.original?.status)}
              >
                <KTIcon iconName='check' className='text-primary text-hover-white fs-1' />
              </button>
              <button
                className='btn h-35px btn-icon btn btn-light-danger'
                onClick={() => onStatusChange(props.row.original.uuid, false)}
                disabled={
                  props?.row?.original?.status != null && !Boolean(props?.row?.original?.status)
                }
              >
                <KTIcon iconName='cross' className='text-danger text-hover-white fs-1' />
              </button>
            </>
          )
        },
      },
    ],
    []
  )

  const IPListTable = useTable(
    {
      columns: columns as readonly Column<object>[],
      data: filteredData.ipaddress,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { RangePicker } = DatePicker

  const onStatusChange = async (uuid, status) => {
    try {
      const res = await axiosClientAuth.patch('main/ip-address', {
        uuid: uuid,
        status: status,
      })
      if (res.data.status) {
        window.location.reload();
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onDateFilterChange = (value, datestring: any) => {
    if (datestring.length == 0 || (datestring[0] == '' && datestring[1] == '')) {
      setFilteredData(data);
      return;
    }
    const temp1 = data.ipaddress.filter((ele: any) => {
      const routeDate = moment(new Date(ele?.created_at)).format("DD-MM-YYYY");
      const startDate = moment(new Date(datestring[0])).format("DD-MM-YYYY")
      const endDate = moment(new Date(datestring[1])).format("DD-MM-YYYY")

      return routeDate >= startDate && routeDate <= endDate;
    })
    setFilteredData({ ...filteredData, ipaddress: temp1 })
    setDateFilter(datestring)
  }

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>IP List</span>
          <span className='text-muted fw-semibold fs-7'>Total {filteredData.ipaddress.length} IP</span>
        </h3>
        <div className='d-flex align-items-center'>
          <RangePicker
            className='bg-light text-white border-0 shadow-none w-100'
            style={{ height: '34px' }}
            onChange={onDateFilterChange}
          />
          <select
            className='form-select w-auto form-select-sm form-select-solid mx-2'
            value={IPListTable.headers[3].filterValue}
            onChange={(e) => IPListTable.headers[3].setFilter(e.target.value)}
          >
            {['All', 'true', 'false'].map((item) => (
              <option value={item == 'All' ? '' : item}>
                {item == "true" ? 'Enabled' : item == "false" ? 'Disabled' : 'All'}
              </option>
            ))}
          </select>
          <select
            className='form-select w-auto form-select-sm form-select-solid me-2'
            value={IPListTable.headers[4].filterValue}
            onChange={(e) => IPListTable.headers[4].setFilter(e.target.value)}
          >
            {['All', 'true', 'false'].map((item) => (
              <option value={item == 'All' ? '' : item}>
                {item == "true" ? 'Blacklisted' : item == "false" ? 'Whitelisted' : 'All'}
              </option>
            ))}
          </select>
          <div className='d-flex align-items-center position-relative my-1 ms-2'>
            <KTIcon iconName='magnifier' className='text-white-500 position-absolute ms-2' />
            <input
              type='text'
              id='kt_filter_search'
              className='form-control form-control-solid form-select-sm w-150px ps-9'
              placeholder='Search IP'
              value={IPListTable.state.globalFilter}
              onChange={(e) => IPListTable.setGlobalFilter(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className='card-body pt-3'>
        <div className='table-responsive'>
          <table
            {...IPListTable.getTableProps()}
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          >
            <thead>
              {IPListTable.headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className={column.render('Header') == 'ACTION' ? 'w-200px' : ''}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <span className='text-gray-400 fs-7'>{column.render('Header')}</span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <KTIcon iconName='down' className='text-gray ms-1' />
                        ) : (
                          <KTIcon iconName='up' className='text-gray ms-1' />
                        )
                      ) : (
                        ''
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...IPListTable.getTableBodyProps()}>
              {(IPListTable.page.length > 0 &&
                IPListTable.page.map((row) => {
                  IPListTable.prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td className='fw-bold' {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  )
                })) || (
                  <tr>
                    <td colSpan={5} className='text-center'>
                      <span className='fw-bold'>No data available in table</span>
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
          <div className='d-flex justify-content-between align-items-center mt-5'>
            <div>
              <select
                className='form-select form-select-sm form-select-solid'
                value={IPListTable.state.pageSize}
                onChange={(e) => IPListTable.setPageSize(parseInt(e.target.value))}
              >
                {[10, 25, 50, 100].map((item) => (
                  <option>{item}</option>
                ))}
              </select>
            </div>
            <div>
              <span>
                Page{' '}
                <strong>
                  {IPListTable.state.pageIndex + 1} of {IPListTable.pageOptions.length}
                </strong>
              </span>
            </div>
            <div>
              <button
                className='btn btn-sm'
                onClick={() => IPListTable.previousPage()}
                disabled={!IPListTable.canPreviousPage}
              >
                <KTIcon iconName='left' className='text-gray fs-1 me-5' />
              </button>
              <button
                className='btn btn-sm'
                onClick={() => IPListTable.nextPage()}
                disabled={!IPListTable.canNextPage}
              >
                <KTIcon iconName='right' className='text-gray fs-1 me-5' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TableWidgetIPList
