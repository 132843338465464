/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { toAbsoluteUrl } from '../../../helpers'

type Props = {
  className?: string
  bgColor?: string
  title?: string
  title2?: string
}
const TilesWidget2 = ({
  className,
  bgColor = '#663259',
  title = 'For Bulk Upload Report',
  title2 = 'Click on below button',
}: Props) => {
  return (
    <div
      className={clsx('card bgi-no-repeat bgi-size-contain', className)}
      style={{
        minHeight: "168px",
        backgroundColor: bgColor,
        backgroundPosition: 'right bottom',
        backgroundSize: '60%',
        backgroundImage: `url("${toAbsoluteUrl('/media/svg/misc/infography.svg')}")`,
      }}
    >
      <div className='card-body d-flex flex-column justify-content-between'>
        <h2 className='text-white fw-bold mb-5'>
          {title} <br /> 
          <span className='text-gray-300 fs-4'>{title2}{' '}</span>
        </h2>

        <div className='m-0'>
          <a href='/bulkreport' className='btn btn-danger fw-semibold px-6 py-3'>
            Bulk Report
          </a>
        </div>
      </div>
    </div>
  )
}

export { TilesWidget2 }
