/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { useThemeMode } from '../../../layout/theme-mode/ThemeModeProvider'
import { KTIcon, toAbsoluteUrl } from '../../../../helpers'
import { getCSSVariableValue } from '../../../../assets/ts/_utils'
import { axiosClientAuth } from '../../../../../app/utils/axiosClient'
import moment from 'moment'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  data: any
  data1: any
}

const MixedWidgetIpStats: React.FC<Props> = ({ className, chartColor, chartHeight, data, data1 }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart1 = new ApexCharts(chartRef.current, chart1Options(chartColor, chartHeight, data1))
    if (chart1) {
      chart1.render()
    }

    return chart1
  }

  useEffect(() => {
    let chart1;
    if(data1){
      chart1 = refreshChart()
    }

    return () => {
      if (chart1) {
        chart1.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode, data1])

  // const [data, setData] = useState(mData);

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>IP Stats</span>

          <span className='text-muted fw-semibold fs-7'>Total {data.ipaddress_count.total} IP registred</span>
        </h3>
      </div>

      <div className='card-body d-flex flex-column'>
        <div ref={chartRef} className='mixed-widget-5-chart card-rounded-top'></div>

        <div className='pt-10 pb-10 flex-grow-1'>
          <div className='row g-0 mt-5 mb-10'>
            <div className='col'>
              <div className='d-flex align-items-center me-2'>
                <div className='symbol symbol-50px me-3'>
                  <div className='symbol-label bg-light-info'>
                    <KTIcon iconName='element-9' className='fs-1 text-info' />
                  </div>
                </div>
                <div>
                  <div className='fs-4 text-dark fw-bold'>{data.ipaddress_count.total}</div>
                  <div className='fs-7 text-muted fw-semibold'>Total IP</div>
                </div>
              </div>
            </div>

            {/* <div className='col'>
              <div className='d-flex align-items-center me-2'>
                <div className='symbol symbol-50px me-3'>
                  <div className='symbol-label bg-light-warning'>
                    <KTIcon iconName='element-9' className='fs-1 text-warning' />
                  </div>
                </div>

                <div>
                  <div className='fs-4 text-dark fw-bold'>{data.ipaddress_count.pending}</div>
                  <div className='fs-7 text-muted fw-semibold'>Pending</div>
                </div>
              </div>
            </div> */}
          </div>

          <div className='row g-0'>
            <div className='col'>
              <div className='d-flex align-items-center me-2'>
                <div className='symbol symbol-50px me-3'>
                  <div className='symbol-label bg-light-success'>
                    <KTIcon iconName='check' className='fs-1 text-success' />
                  </div>
                </div>

                <div>
                  <div className='fs-4 text-dark fw-bold'>{data.ipaddress_count.enabled}</div>
                  <div className='fs-7 text-muted fw-semibold'>Enabled IP</div>
                </div>
              </div>
            </div>

            <div className='col'>
              <div className='d-flex align-items-center me-2'>
                <div className='symbol symbol-50px me-3'>
                  <div className='symbol-label bg-light-danger'>
                    <KTIcon iconName='cross' className='fs-1 text-danger' />
                  </div>
                </div>

                <div>
                  <div className='fs-4 text-dark fw-bold'>{data.ipaddress_count.disabled}</div>
                  <div className='fs-7 text-muted fw-semibold'>Disabled IP</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const chart1Options = (chartColor: string, chartHeight: string, data1: any): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-800')
  const strokeColor = getCSSVariableValue('--bs-gray-300')
  const baseColor = getCSSVariableValue('--bs-' + chartColor) as string
  const lightColor = getCSSVariableValue('--bs-' + chartColor + '-light')

  return {
    series: [
      {
        name: '',
        data: data1?.revenue_per_month,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    fill1: {
      type: 'gradient',
      opacity: 1,
      gradient: {
        type: 'vertical',
        shadeIntensity: 0.5,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 0.375,
        stops: [25, 50, 100],
        colorStops: [],
      },
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: [
        moment(new Date()).subtract(5, 'months').format('MMM'),
        moment(new Date()).subtract(4, 'months').format('MMM'),
        moment(new Date()).subtract(3, 'months').format('MMM'),
        moment(new Date()).subtract(2, 'months').format('MMM'),
        moment(new Date()).subtract(1, 'months').format('MMM'),
        moment(new Date()).format('MMM'),
      ],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      min: 0,
      max: 65,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ' IP Registred'
        },
      },
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      // @ts-ignore
      strokeColor: [baseColor],
      strokeWidth: 3,
    },
  }
}

export { MixedWidgetIpStats }