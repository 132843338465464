/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useState } from 'react'
import IPRegistrationModal from '../../modals/IPRegistrationModal'
import IPBulkUploadModal from '../../modals/IPBulkUploadModal'

type Props = {
  className?: string
}
const TilesWidget4 = ({ className }: Props) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [showBulkUpload, setShowBulkUpload] = useState<boolean>(false);

  return (
    <div className={clsx('card h-150px', className)}>
      <div className='card-body d-flex align-items-center justify-content-between flex-wrap'>
        <div className='me-2'>
          <h2 className='fw-bold text-gray-800 mb-3'>Add IP Address</h2>

          <div className='text-muted fw-semibold fs-6'>
            Whitelist your IP.
          </div>
        </div>
        <div>
          <button
            className='btn btn-primary fw-semibold'
            onClick={() => setShowCreateAppModal(true)}
          >
            Add IP
          </button>
          <button
            className='btn btn-primary fw-semibold ms-2'
            onClick={() => setShowBulkUpload(true)}
          >
            Bulk Upload
          </button>
        </div>
      </div>
      <IPRegistrationModal
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
      />
      <IPBulkUploadModal
        show={showBulkUpload}
        handleClose={() => setShowBulkUpload(false)}
      />
    </div>
  )
}

export { TilesWidget4 }