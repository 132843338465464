/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useState } from 'react'
import { KTIcon } from '../../../helpers'
import { CreateAppModal } from '../../../partials'
import { useLayout } from '../../core'
import { DefaultTitle } from '../header/page-title/DefaultTitle'
import IPRegistrationModal from '../../../partials/modals/IPRegistrationModal'

const Toolbar1 = () => {
  const { classes } = useLayout()

  return (
    <>
      <div className='toolbar py-5 py-lg-15' id='kt_toolbar'>
        <div
          id='kt_toolbar_container'
          className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
        >
          <DefaultTitle />
          <div className='d-flex align-items-center py-1'>
            {/* <a
              className='btn bg-body btn-active-color-primary'
              id='kt_toolbar_primary_button'
              data-bs-theme='light'
            >
              Add IP
            </a> */}
          </div>
        </div>
      </div>
    </>
  )
}

export { Toolbar1 }